<template>
  <tr :style="{'background': props.props.selected ? 'lightyellow' : '', 'color': props.props.selected ? '#000' : ''}">
    <td v-if="config.config.fieldsSelected">
      <v-checkbox v-model="props.props.selected" primary hide-details></v-checkbox>
    </td>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-center" @click="props.props.expanded = !props.props.expanded" style="cursor: pointer;">
      {{props.item.device.id}}
    </td>
    <td class="justify-center" @click="props.props.expanded = !props.props.expanded" style="cursor: pointer;">
      <span>{{props.item.device.nome_disp}}</span>
    </td>
    <td class="justify-center" @click="props.props.expanded = !props.props.expanded" style="cursor: pointer;">
      <div style="width: 100%; text-align: center;">
        <v-icon :style="{color: props.item.device.status_disp ? 'green' : 'red'}" v-html="props.item.device.status_disp ? 'arrow_upward' : 'arrow_downward'"></v-icon><br>
        <strong v-html="props.item.device.status_disp ? 'Disponível' : 'Indisponível'"></strong>
      </div>
    </td>
    <td class="justify-center" @click="props.props.expanded = !props.props.expanded" style="cursor: pointer;">
      <span>{{props.item.device.ip_disp || '-'}}</span>
    </td>
    <td class="justify-center" @click="props.props.expanded = !props.props.expanded" style="cursor: pointer;">
      <span>{{props.item.device.network_names || '-'}}</span>
    </td>
    <td class="justify-center">
      <v-btn small outline @click="addDeviceChilds()">
        Adicionar Interfaces
      </v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn :disabled="!pingIsAvaiable(props.item.device)" small outline @click="pingDialogAction(true, props.item.device.servidor_dados_id, props.item.ip_disp)">
              <v-icon>invert_colors</v-icon>
              Pingar
            </v-btn>
          </div>
        </template>
        <span v-if="pingIsAvaiable(props.item.device)">Clique para pingar este dispositivo.</span>
        <span v-else>O servidor deste dispositivo está offline ou desatualizado.</span>
      </v-tooltip>
      <ping-dialog v-if="pingDialog.active" :network-server-id="pingDialog.server" :host-to-ping="pingDialog.host" :active="pingDialog.active" v-on:active="pingDialogAction"></ping-dialog>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  import PingDialog from '@/modules/network/pages/NetworkServer/Dialogs/PingDialog'
  export default {
    name: 'NetworkDevices',
    data () {
      return {
        pingDialog: {
          active: false,
          host: null,
          server: null
        }
      }
    },
    methods: {
      pingDialogAction: function (active = false, server = null, host = null) {
        this.pingDialog.host = host
        this.pingDialog.server = server
        this.pingDialog.active = active
      },
      pingIsAvaiable: function (device) {
        return device.network_server.status && device.network_server.last_interaction_in_minutes < 5
      },
      addDeviceChilds: function () {
        this.$WiEditDialog({
          wiConfig: 'ticket-device-child',
          data: {
            device_id: this.props.item.device.id,
            ticket_id: this.$route.params.id
          },
          redirectOnSubmit: false
        })
      }
    },
    mounted() {
      console.log(this.props.item)
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions,
      PingDialog
    }
  }
</script>